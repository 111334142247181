import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import {
	Ecommerce,
	Orders,
	Calendar,
	ColorPicker,
	ColorMapping,
	Employees,
	Kanban,
	Area,
	Bar,
	Financial,
	Line,
	Pie,
	Pyramid,
	Stacked,
	Editor,
	NotFound,
	Pdf,
} from "./pages";
import Tester from "./pages/Tester";
import PelamarLulus from "./pages/PelamarLulus";
import BarOpUnity from "./pages/charts/BarOpUnity";
import UserPelamar from "./pages/UserPelamar";

function App() {
	return (
		<div>
			<Routes>
				{/* Pages Dashboard */}
				<Route exact path="/" element={<Tester />} />
				<Route exact path="/dashboard" element={<Ecommerce />} />
				<Route exact path="*" element={<NotFound />} />
				{/* Pages */}
				<Route exact path="/semuadata" element={<Orders />} />
				<Route exact path="/userctk" element={<UserPelamar />} />
				<Route exact path="/pelamarlulus" element={<PelamarLulus />} />
				{/* <Route exact path="/dataloader" element={<Employees />} />
				<Route exact path="/datawelder" element={<Costumers />} /> */}
				{/* Apps */}
				{/* <Route exact path="/dataoperator" element={<Calendar />} />
				<Route exact path="/datasecurity" element={<Kanban />} />
				<Route exact path="/datahouse" element={<Editor />} /> */}
				<Route exact path="/tambahpelamar" element={<ColorPicker />} />
				<Route exact path="/opunity" element={<BarOpUnity />} />
				{/* Charts */}
				<Route exact path="/semuaposisi" element={<Line />} />
				<Route exact path="/tambahposisi" element={<Area />} />
				<Route exact path="/tabledimanis" element={<Bar />} />
				<Route exact path="/tambahperusahaan" element={<Pie />} />
				<Route exact path="/semuaperusahaan" element={<Financial />} />
				<Route exact path="/tablehistory" element={<ColorMapping />} />
				<Route exact path="/pdfpelamar" element={<Pyramid />} />
				<Route exact path="/permintaantenagakerja" element={<Stacked />} />
				<Route path="/pdf" element={<Pdf />} />;
			</Routes>
		</div>
	);
}
export default App;
